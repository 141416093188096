import jQuery from 'jquery';

const subscribeFormToggler = () => {
  jQuery('.js-subscribe-toggler').on('click', function subscribeFormToggle() {
    jQuery(this).parent().parent().find('.c-subscribe__fieldset')
      .toggleClass('active');
    jQuery(this).parent().parent().find('.js-cm-first-name-input')
      .focus();
  });
};

export default subscribeFormToggler;
